import React, { Component } from "react";
import axios from "axios";
import { pibitUrl, clientId, clientIdInternal } from "../config";
import SmallLoader from "./smallLoader";
import "../index.css";
import HistoricalClaims from "./HistoricalClaims";
import toast, { Toaster } from "react-hot-toast";
import { v4 } from "uuid";

const CLIENTID = clientId;
const client_id_internal = clientIdInternal;

class UploadFormAcord extends Component {

  componentDidMount() {
    // Get the full URL
    const fullUrl = window.location.href;

    // Split the URL by '/' to get parts
    const urlParts = fullUrl.split('/');

    console.log('URL Parts:', urlParts);
  }
  
  handleLossOcrParse = (data) => {
    this.setState({ awaitingFile: false, disableBind: false });
    if (data.fileType === "success") {
      this.setState({
        parseMesssage: "Parsing complete!",
        parseError: false,
        urlSuccess: true,
        signedUrl: data.url,
      });
    } else if (data.fileType === "error") {
      this.setState({
        parseMesssage:
          "Unable to process the loss file. Please contact admin@insurecomp.com for further clarification.",
        parseError: true,
      });
    } else {
      this.setState({
        parseMesssage:
          "Unable to generate the download link. Please contact admin@insurecomp.com for further clarification.",
        parseError: true,
      });
    }
  };

  s3Poller = (fileName) => {
    axios
      .get(
        pibitUrl + `/api/s3PollerJson/ocr-doc-demo-acord/${fileName}/us-east-1`
      )
      .then((result) => {
        if (result.data.fileType) {
          this.handleLossOcrParse(result.data);
        } else {
          this.s3Poller(fileName);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        this.setState({
          parseError: true,
          awaitingFile: false,
          parseMesssage:
            "Unable to process the loss file. Please contact admin@insurecomp.com for further clarification.",
        });
      });
  };
  // uploadFile = (event) => {
  //   this.setState({ uploadingFile: true, parseError: false });
  //   this.setState({
  //     error: false,
  //     urlSuccess: false,
  //     signedUrl: "",
  //     awaitingFile: false,
  //   });
  //   const that = this;
  //   event.preventDefault();

  //   const formData = new FormData();
  //   formData.append("file", this.state.file[0]);
  //   console.log(formData);
  //   axios
  //     .post(pibitUrl + "/api/getFileParsedData", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then((response) => {
  //       that.setState({ uploadingFile: false });
  //       that.setState({ awaitingFile: true });
  //       const fileName = response.data.fileName;
  //       const folder = fileName.split(".")[0];
  //       console.log("file uploaded:", folder);
  //       this.s3Poller(folder);
  //     })
  //     .catch((error) => {
  //       this.setState({ uploadingFile: false });
  //       console.log("error", error);
  //     });
  // };

  // please do test this whole function. above commented code was earlier. now uploadFile is completely for now uploading.

  validationCheck = () => {
    let { selectedWorkFlow } = this.state;
    if (!selectedWorkFlow) {
      this.setState({ selectedWorkFlowError: true });
      return false;
    }
    this.setState({ selectedWorkFlowError: false });
    return true;
  };

  uploadFile = async (event) => {
    let { selectedWorkFlow } = this.state;
    this.setState({
      excelFileBase64String: "",
      summaryExcelData: "",
      jsonDataDownload: "",
    });
    let dropdownselectcheck = this.validationCheck();
    let workFlowMap = {
      summaryData: { pibitWorkFlow: "summary", key: "summaryData" },
      detailedData: { pibitWorkFlow: "detailed", key: "detailedData" },
      both: { pibitWorkFlow: "summary", key: "summaryData" },
    };

    if (dropdownselectcheck) {
      this.setState({ uploadingFile: true, parseError: false });
      try {
        let fileContentType = this.state.file[0]["type"];
        let options = { headers: { "Content-Type": fileContentType } };
        let postFileData = {
          filename: this.state.file[0]["name"],
          workFlow: workFlowMap[selectedWorkFlow]["pibitWorkFlow"],
          client_id: CLIENTID,
          userID: v4(),
          additional_details: {
            key_to_differ_client: client_id_internal,
          }
        };

        const response = await axios.post(
          pibitUrl + "/api/getPresignedUrldata",
          postFileData,
          options
        );
        let {
          uploadUrl,
          FormDataTokenFields,
          requestId,
          documentId,
          clientId,
        } = response.data;
        const formData = new FormData();
        for (const [key, value] of Object.entries(FormDataTokenFields)) {
          formData.append(key, value);
        }
        formData.append("file", this.state.file[0]);
        const uploadResponse = await axios.post(uploadUrl, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        let checkPibitStatus;
        const fetchpibitStatus = (requestId, documentId, dataKey) => {
          let jsonDataResponse, txtDataResponse;
          let response = {};
          return new Promise(async (resolve, reject) => {
            try {
              response = await axios.post(pibitUrl + "/api/checkPibitStatus", {
                requestId,
                documentId,
                dataKey,
                client_id: CLIENTID
              }); 
              /// getting the presignedurl and getting s3 bucket object
              [jsonDataResponse, txtDataResponse] = await Promise.allSettled([
                axios.get(response?.data?.preSignedUrlJsonData.value),
                axios.get(response?.data?.preSignedUrlTxtData.value),
              ]);
              
              
              response.data[dataKey].data = jsonDataResponse?.value?.data || [];
              response.data[dataKey].excelFileBase64String =
                txtDataResponse?.value?.data || "";

              resolve(response.data);
            } catch (error) {
              if (Object.keys(response).length > 0) {
                if (response?.data?.[dataKey]?.data?.length === 0) {
                  response.data[dataKey].data =
                    jsonDataResponse?.value?.data || [];
                }

                if (
                  response?.data?.[dataKey]?.excelFileBase64String?.length === 0
                ) {
                  response.data[dataKey].excelFileBase64String =
                    txtDataResponse?.value?.data || "";
                }

                resolve(response.data);
              } else {
                console.error("Error fetching Pibit status:", error);
                reject(error);
              }
            }
          });
        };

        let dataKey = workFlowMap[selectedWorkFlow]["key"];
        const extractDocumentPoller = async (dataKey) => {
          do {
            await new Promise((resolve) => setTimeout(resolve, 10000));
            checkPibitStatus = await fetchpibitStatus(
              requestId,
              documentId,
              dataKey
            );
          } while (checkPibitStatus === "processing");
        };
        if (selectedWorkFlow === "summaryData" || selectedWorkFlow === "both") {
          await extractDocumentPoller(dataKey);
          if (selectedWorkFlow === "summaryData") {
            this.setState({
              uploadingFile: false,
              jsonDataDownload: checkPibitStatus?.[dataKey]?.["data"],
              summaryExcelData:
                checkPibitStatus?.[dataKey]?.["excelFileBase64String"],
            });
          } else {
            this.setState({
              uploadingFile: true,
              jsonDataDownload: checkPibitStatus?.[dataKey]?.["data"],
              summaryExcelData:
                checkPibitStatus?.[dataKey]?.["excelFileBase64String"],
            });
          }
        } else if (selectedWorkFlow === "detailedData") {
          await extractDocumentPoller(dataKey);
          this.setState({
            uploadingFile: false,
            excelFileBase64String:
              checkPibitStatus?.[dataKey]?.["excelFileBase64String"],
          });
        }

        if (selectedWorkFlow === "both") {
          this.setState({ uploadingFile: true });

          let initiateParam = {
          requestId,
          clientToken: clientId?.client_token 
        }

          const initiateExtracton = await axios.post(
            pibitUrl + "/api/initiateExtraction",
            initiateParam
          );

          await extractDocumentPoller("detailedData");
          this.setState({
            uploadingFile: false,
            excelFileBase64String:
              checkPibitStatus?.["detailedData"]?.["excelFileBase64String"],
          });
        }
      } catch (error) {
        console.error("Error:", error);
        this.setState({ uploadingFile: false });
      }
    }
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  handleDragEnter = (event) => {
    event.preventDefault();
    console.log(event?.dataTransfer?.items[0]?.type);
    if (
      event?.dataTransfer?.items[0]?.type !== "application/zip" &&
      event?.dataTransfer?.items[0]?.type !== "application/pdf"
    ) {
      toast.error("Invalid file extension");
      return;
    }
  };
  downloadJsonFileHandler = () => {
    const { jsonDataDownload } = this.state;
    const blob = new Blob([JSON.stringify(jsonDataDownload)], {
      type: "text/json;charset=utf-8",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Summary.json";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  downloadExcelFileHandler = (excelFileBase64String, fileName) => {
    try {
      const mediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,`;
      const dataURI = mediaType + excelFileBase64String;

      const link = document.createElement("a");
      link.href = dataURI;
      link.download = `${fileName}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel file:", error.message);
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadingFile: false,
      awaitingFile: false,
      parseError: false,
      parseMesssage: "",
      urlSuccess: false,
      signedUrl: "",
      excelFileBase64String: "",
      selectedWorkFlow: "summaryData",
      selectedWorkFlowError: false,
      summaryExcelData: "",
      jsonDataDownload: [],
    };
  }
  render() {
    const {
      uploadingFile,
      awaitingFile,
      urlSuccess,
      signedUrl,
      parseError,
      parseMesssage,
      selectedWorkFlow,
      selectedWorkFlowError,
      jsonDataDownload,
      excelFileBase64String,
      summaryExcelData,
    } = this.state;
    

    return (
      <div className="container-fluid">
        <form className="col-md-12">
          <div className="form-group">
            <select
              className="mb-3"
              style={{ padding: "8px" }}
              value={selectedWorkFlow}
              onChange={(e) => {
                this.setState({
                  selectedWorkFlow: e.target.value,
                  selectedWorkFlowError: false,
                  jsonDataDownload: [],
                  excelFileBase64String: "",
                });
              }}
            >
              <option value="summaryData">Summary Extraction</option>
              <option value="detailedData">Detailed Extraction</option>
              <option value="both">Summary & Detailed Extraction</option>
            </select>

            <div>
              <label
                for="formFileLg"
                class="form-label  "
                style={{ fontWeight: 700 }}
              >
                Select a Loss run pdf for extraction
              </label>
              <input
                className="form-control form-control-lg"
                id="formFileLg"
                label="upload file"
                accept=".zip,.pdf"
                type="file"
                onChange={this.handleFileUpload}
                onDrop={this.handleDragEnter}
                style={{ maxWidth: "30%", padding: "6px 12px" }}
              />
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.uploadFile}
          >
            Submit
          </button>
        </form>
        <div className="col-md-12 mt-2">
          {uploadingFile && (
            <span>
              Parsing file <SmallLoader />
            </span>
          )}
          {awaitingFile && (
            <span>
              Waiting for file creation <SmallLoader />
            </span>
          )}
          {urlSuccess && (
            <div>
              <a href={signedUrl}>
                {parseMesssage} Click here to download your file.
              </a>
            </div>
          )}
          {parseError && <span>{parseMesssage}</span>}
          {selectedWorkFlowError && (
            <span>Please select the work flow from dropdown</span>
          )}
          {jsonDataDownload && Object.keys(jsonDataDownload).length > 0 && (
            <button
              className="btn btn-success mr-4"
              onClick={this.downloadJsonFileHandler}
            >
              Download Summary JSON
            </button>
          )}
          {summaryExcelData && (
            <button
              className="btn btn-success mr-4"
              onClick={() => {
                this.downloadExcelFileHandler(summaryExcelData, "summaryExcel");
              }}
            >
              Download Summary Excel
            </button>
          )}

          {excelFileBase64String && excelFileBase64String.length > 0 && (
            <button
              className="btn btn-success "
              onClick={() => {
                this.downloadExcelFileHandler(
                  excelFileBase64String,
                  "detailedExcel"
                );
              }}
            >
              Download Detailed Excel
            </button>
          )}
        </div>
        {Object.keys(jsonDataDownload).length > 0 && (
          <HistoricalClaims jsonDataDownload={jsonDataDownload} />
        )}
        <Toaster />
      </div>
    );
  }
}

export default UploadFormAcord;
